import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
} from "../../../assets/images/index";

const BestSellers = () => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Our Bestsellers" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        <Product
          _id="1011"
          img={bestSellerOne}
          productName="Marquina Black Marble"
          price="--"
          color="Blank and White"
          badge={false}
          des="Marquina Black Marble is a luxurious natural stone known for its intense black background adorned with striking white veins. This high-contrast design exudes sophistication and exclusivity, making it a favorite choice for modern, minimalistic, and high-end interiors."
        />
        <Product
          _id="1012"
          img={bestSellerTwo}
          productName="Portoro Black Marble"
          price="--"
          color="Gray"
          badge={false}
          des="Portoro Black Marble is an exquisite natural stone prized for its deep black background laced with rich golden and white veining, creating a breathtakingly luxurious appeal. This rare and prestigious marble is a symbol of opulence, making it a top choice for high-end interiors and statement designs."
        />
        <Product
          _id="1013"
          img={bestSellerThree}
          productName="Crema Premium Marble"
          price="--"
          color="Cream"
          badge={false}
          des="Crema Premium Marble is a sophisticated natural stone known for its creamy beige background with subtle veins of light gold and ivory. Its smooth and warm tones bring a sense of tranquility and luxury to any space, making it a perfect choice for refined interiors."
        />
        <Product
          _id="1014"
          img={bestSellerFour}
          productName="Travertine "
          price="--"
          color="Mix Cream"
          badge={false}
          des="Travertine is a stunning natural stone known for its earthy tones of beige, ivory, and light brown, enhanced by its characteristic pitted texture and rich patterns. This stone brings a sense of warmth and rustic elegance to any space, making it a perfect choice for both contemporary and traditional designs."
        />
      </div>
    </div>
  );
};

export default BestSellers;
