import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import foto1 from "../../assets/images/products/foto_marmer.jpg";
import foto2 from "../../assets/images/products/Pengrajin-Marmer.jpg";
const Journal = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);

  const blogPosts = [
    {
      id: 1,
      title: "Marmer Tulungagung: The Heart of Indonesia’s Marble Industry",
      excerpt:
        "Marmer Tulungagung is widely known as one of the most iconic sources of high-quality marble in Indonesia. Sourced from the Tulungagung region, this marble is famed for its beautiful natural patterns, ranging from pure whites to shades of beige, making it a popular choice for both domestic and international projects.",
      image: foto1, // Replace with actual image path
    },
    // {
    //   id: 2,
    //   title: "Master Artisans Behind the Beauty",
    //   excerpt:
    //     "Pengrajin marmer (marble artisans) are the skilled craftsmen who bring the beauty and elegance of marble to life. These artisans have a deep-rooted history and expertise in working with marble, transforming raw stone into stunning creations that enhance both residential and commercial spaces.",
    //   image: foto2, // Replace with actual image path
    // },
  ];

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Journals" prevLocation={prevLocation} />
      <div className="pb-10">
        <h1 className="max-w-[600px] text-base text-lightText mb-8">
          <span className="text-primeColor font-semibold text-lg">
            {" "}
            At Marmer Indonesia
          </span>{" "}
          , we believe in sharing knowledge, insights, and the latest trends in
          the world of marble, granite, and natural stone. Our journal is a
          space for us to offer tips, project inspiration, and updates to help
          you make the best choices for your home or commercial design.
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {blogPosts.map((post) => (
            <div
              key={post.id}
              className="border rounded-lg overflow-hidden shadow-lg"
            >
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h2 className="text-lg font-semibold text-primeColor">
                  {post.title}
                </h2>
                <p className="text-lightText text-base mt-2">{post.excerpt}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Journal;
