import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { productOfTheYear } from "../../assets/images";

const About = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state?.data || "");
  }, [location]);

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="About" prevLocation={prevLocation} />
      <div className="flex pb-10 gap-8">
        <div className="flex-1">
          <h1 className="max-w-[600px] text-base text-lightText mb-2">
            <span className="text-primeColor font-semibold text-lg">
              At Marmer Indonesia
            </span>
            , we are passionate about bringing the beauty and durability of
            premium marble, granite, and natural stone to your projects. Whether
            you're working on a luxury home renovation, a commercial design, or
            simply looking for the perfect material to elevate your space, we
            have the ideal solution for you.
          </h1>
          <h1 className="max-w-[600px] text-base text-lightText mb-4 mt-4">
            Explore the world of luxury stone with Marmer Indonesia and bring
            your dream spaces to life. With a focus on quality, expertise, and
            customer service, we’re here to help you choose the perfect stone
            for your project.
          </h1>
          <Link to="/shop">
            <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
              Continue Shopping
            </button>
          </Link>
        </div>
        <div className="flex-1 hidden md:block">
          <img
            src={productOfTheYear}
            alt="About Orebi"
            className="w-full h-auto object-cover"
          />
        </div>
      </div>
      <div className="w-full h-96 mt-10">
        <iframe
          title="Tulungagung Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.527119379239!2d111.9028013143208!3d-8.065679482812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e78e3f7b1b1b1b1%3A0x1b1b1b1b1b1b1b1b!2sTulungagung%2C%20East%20Java%2C%20Indonesia!5e0!3m2!1sen!2sus!4v1633021234567!5m2!1sen!2sus"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default About;
