import React, { useState } from "react";
import NavTitle from "./NavTitle";

const Category = ({ onCategorySelect }) => {
  const [showSubCatOne, setShowSubCatOne] = useState(false);
  const items = [
    // { _id: 990, title: "Local Indonesian Marble", icons: true },
    { _id: 992, title: "Marble", icons: true },
    { _id: 993, title: "Granite" },
    { _id: 994, title: "Onyx" },
    { _id: 995, title: "Stone Accessories" },
  ];

  const handleCategoryClick = (title) => {
    if (onCategorySelect) {
      onCategorySelect(title);
    }
  };

  return (
    <div className="w-full">
      <NavTitle title="Shop by Category" icons={false} />
      <div>
        <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
          {items.map(({ _id, title, icons }) => (
            <li
              key={_id}
              className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center justify-between"
              onClick={() => handleCategoryClick(title)}
            >
              {title}
              {icons && (
                <span
                  onClick={() => setShowSubCatOne(!showSubCatOne)}
                  className="text-[10px] lg:text-xs cursor-pointer text-gray-400 hover:text-primeColor duration-300"
                ></span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Category;
