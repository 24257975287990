import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  newArrFive,
} from "../../../assets/images/index";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";

const NewArrivals = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="w-full pb-16 mt-5">
      <Heading heading="New Arrivals" />
      <Slider {...settings}>
        <div className="px-2">
          <Product
            _id="100001"
            img={newArrOne}
            productName="Rainbow Color Granite"
            price="--"
            color="Rainbow"
            badge={true}
            des="Rainbow Color Granite is a stunning natural stone that showcases a mesmerizing blend of vibrant hues, including deep reds, rich golds, earthy browns, and subtle blues. This unique color combination creates a luxurious and artistic appeal, making it a top choice for high-end interiors and exteriors."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100002"
            img={newArrTwo}
            productName="Multicolor Black Granite"
            price="--"
            color="Black"
            badge={true}
            des="Multicolor Black Granite is a breathtaking natural stone known for its deep black base infused with striking streaks of red, gold, brown, and gray. This unique combination creates a luxurious and sophisticated aesthetic, making it a favorite choice for modern and classic designs alike."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100003"
            img={newArrThree}
            productName="Carrara Super Marble"
            price="--"
            color="White"
            badge={true}
            des="Carrara Super Marble is a premium-grade natural stone renowned for its pure white background and delicate gray veining, offering a timeless and sophisticated aesthetic. Originating from the world-famous Carrara quarries in Italy, this marble is a symbol of luxury and refined taste, perfect for high-end interiors."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100004"
            img={newArrFour}
            productName="Emperador Marble"
            price="--"
            color="Mixed (Black, Gold)"
            badge={true}
            des="Emperador Marble is a luxurious natural stone celebrated for its deep brown base interwoven with golden and white veins, creating a warm, elegant, and timeless appeal. Sourced from the finest quarries, this marble is a top choice for high-end interiors, adding a touch of opulence to any space."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100005"
            img={newArrFive}
            productName="Multicolor Granite"
            price="--"
            color="Mixed"
            badge={true}
            des="Multicolor Granite is a stunning natural stone featuring a dynamic blend of rich reds, deep browns, elegant blacks, and warm golds. Its unique color variations create an eye-catching, luxurious look, making it a top choice for both modern and classic designs."
          />
        </div>
      </Slider>
    </div>
  );
};

export default NewArrivals;
