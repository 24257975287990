import React from "react";
import wa from "../../assets/images/wa.png";
const WhatsAppButton = () => {
  const handleClick = () => {
    const message = "Hello, I would like to consult with you.";
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/6281311974640?text=${encodedMessage}`, "_blank");
  };
  return (
    <button
      onClick={handleClick}
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        backgroundColor: "#25D366",
        border: "none",
        borderRadius: "50%",
        padding: "10px",
        cursor: "pointer",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
      }}
    >
      <img src={wa} alt="WhatsApp" style={{ width: "40px", height: "40px" }} />
    </button>
  );
};

export default WhatsAppButton;
