import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrFive,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  a1,
  a3,
  a4,
  a6,
  a7,
  a5,
  a8,
  a9,
  a2,
  a10,
  a11,
  a12,
  a13,
  a14,
  a15,
} from "../assets/images/index";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1002,
    title: "Shop",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "About",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Contact",
    link: "contact",
  },
  {
    _id: 1005,
    title: "Journal",
    link: "/journal",
  },
];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
  {
    _id: "100001",
    img: newArrOne,
    productName: "Rainbow Color Granite",
    tipe: "Imported",
    price: "--",
    color: "Rainbow",
    badge: true,
    des: "Rainbow Color Granite is a stunning natural stone that showcases a mesmerizing blend of vibrant hues, including deep reds, rich golds, earthy browns, and subtle blues. This unique color combination creates a luxurious and artistic appeal, making it a top choice for high-end interiors and exteriors.",
  },
  {
    _id: "100002",
    img: newArrTwo,
    productName: "Multicolor Black Granite",
    tipe: "Imported",
    price: "--",
    color: "Black",
    badge: true,
    des: "Multicolor Black Granite is a breathtaking natural stone known for its deep black base infused with striking streaks of red, gold, brown, and gray. This unique combination creates a luxurious and sophisticated aesthetic, making it a favorite choice for modern and classic designs alike.",
  },
  {
    _id: "100003",
    img: newArrThree,
    productName: "Carrara Super Marble",
    tipe: "Imported",
    price: "--",
    color: "White",
    badge: true,
    des: "Carrara Super Marble is a premium-grade natural stone renowned for its pure white background and delicate gray veining, offering a timeless and sophisticated aesthetic. Originating from the world-famous Carrara quarries in Italy, this marble is a symbol of luxury and refined taste, perfect for high-end interiors.",
  },
  {
    _id: "100004",
    img: newArrFour,
    productName: "Emperador Marble",
    tipe: "Imported",
    price: "--",
    color: "Mixed (Black, Gold)",
    badge: true,
    des: "Emperador Marble is a luxurious natural stone celebrated for its deep brown base interwoven with golden and white veins, creating a warm, elegant, and timeless appeal. Sourced from the finest quarries, this marble is a top choice for high-end interiors, adding a touch of opulence to any space.",
  },
  {
    _id: "100005",
    img: newArrFive,
    productName: "Multicolor Granite",
    tipe: "Imported",
    tipe: "Imported",
    price: "--",
    color: "Mixed",
    badge: true,
    des: "Multicolor Granite is a stunning natural stone featuring a dynamic blend of rich reds, deep browns, elegant blacks, and warm golds. Its unique color variations create an eye-catching, luxurious look, making it a top choice for both modern and classic designs.",
  },
  {
    _id: "1011",
    img: bestSellerOne,
    productName: "Marquina Black Marble",
    price: "--",
    color: "Blank and White",
    badge: false,
    des: "Marquina Black Marble is a luxurious natural stone known for its intense black background adorned with striking white veins. This high-contrast design exudes sophistication and exclusivity, making it a favorite choice for modern, minimalistic, and high-end interiors.",
  },
  {
    _id: "1012",
    img: bestSellerTwo,
    productName: "Portoro Black Marble",
    tipe: "Imported",
    price: "--",
    color: "Gray",
    badge: false,
    des: "Portoro Black Marble is an exquisite natural stone prized for its deep black background laced with rich golden and white veining, creating a breathtakingly luxurious appeal. This rare and prestigious marble is a symbol of opulence, making it a top choice for high-end interiors and statement designs.",
  },
  {
    _id: "1013",
    img: bestSellerThree,
    productName: "Crema Premium Marble",
    tipe: "Imported",
    price: "--",
    color: "Cream",
    badge: false,
    des: "Crema Premium Marble is a sophisticated natural stone known for its creamy beige background with subtle veins of light gold and ivory. Its smooth and warm tones bring a sense of tranquility and luxury to any space, making it a perfect choice for refined interiors.",
  },
  {
    _id: "1015",
    img: bestSellerFour,
    productName: "Travertine",
    tipe: "Imported",
    price: "--",
    color: "Mix Cream",
    badge: false,
    des: "Travertine is a stunning natural stone known for its earthy tones of beige, ivory, and light brown, enhanced by its characteristic pitted texture and rich patterns. This stone brings a sense of warmth and rustic elegance to any space, making it a perfect choice for both contemporary and traditional designs.",
  },
  {
    _id: "1016",
    img: a1,
    productName: "Granite Nero Absolute",
    price: "--",
    color: "Black",
    badge: false,
    des: "Granite Nero Absolute (also known as Absolute Black Granite) is one of the finest black granites sourced from India. It is renowned for its deep black color, smooth texture, and high durability, making it a preferred choice for luxury interiors and exteriors.",
  },
  {
    _id: "1017",
    img: a2,
    productName: "Magma Gold Granite",
    price: "--",
    color: "Black Gold",
    badge: false,
    des: "Magma Gold Granite is a breathtaking natural stone from Brazil, known for its rich golden, black, and deep brown veining that resembles flowing lava. This exotic granite brings warmth, elegance, and a dramatic touch to any space, making it a favorite for luxury interior and exterior designs.",
  },
  {
    _id: "1018",
    img: a3,
    productName: "Panda White Marble",
    price: "--",
    color: "Black White",
    badge: false,
    des: "Panda White Marble is a luxurious and eye-catching natural stone known for its striking contrast between pure white and bold black veins, resembling the graceful patterns of a panda. This high-end marble is often sourced from China and is a top choice for elegant interior designs.",
  },
  {
    _id: "1019",
    img: a4,
    productName: "Sakura Exotic Marble",
    price: "--",
    color: "Mixed",
    badge: false,
    des: "Sakura Exotic Marble is a stunning and rare natural stone, inspired by the delicate beauty of cherry blossoms. This premium marble features a soft blend of pink, white, and subtle gray veining, creating an elegant and timeless aesthetic for luxury interiors.",
  },
  {
    _id: "1020",
    img: a5,
    productName: "Patagonia White Quartzite",
    price: "--",
    color: "Mixed",
    badge: false,
    des: "Patagonia White Quartzite is an extraordinary natural stone known for its breathtaking mix of white, beige, gray, and gold crystalline patterns. This exotic quartzite, sourced primarily from Brazil, is a perfect combination of beauty and durability, making it a top choice for high-end interior designs.",
  },
  {
    _id: "1021",
    img: a6,
    productName: "Picasso Classic Quartz",
    price: "--",
    color: "Mixed",
    badge: false,
    des: "Picasso Classic Quartz is a premium engineered stone inspired by the artistic, flowing patterns found in natural marble. Featuring a harmonious blend of soft white, gray, and subtle gold veining, this quartz offers a luxurious yet durable solution for modern interiors.",
  },
  {
    _id: "1022",
    img: a7,
    productName: "Blue Forest Onyx",
    price: "--",
    color: "Mixed",
    badge: false,
    des: "Blue Forest Onyx is a mesmerizing natural stone known for its vibrant blue, gold, and white veining, resembling a mystical forest with flowing water. This rare and exotic onyx is a premium choice for luxury interiors, offering a breathtaking combination of translucency, elegance, and uniqueness.",
  },
  {
    _id: "1023",
    img: a8,
    productName: "Cardellino White Marble",
    price: "--",
    color: "Mixed",
    badge: false,
    des: "Cardellino White Marble is a premium Italian marble celebrated for its soft white background with delicate gray veining, offering a timeless and elegant aesthetic. This luxurious natural stone is highly sought after for its refined beauty and durability, making it an excellent choice for both classic and modern interiors.",
  },
  {
    _id: "1024",
    img: a9,
    productName: "Statuario Marble",
    price: "--",
    color: "Mixed",
    badge: false,
    des: "Statuario Marble is one of the most prestigious Italian marbles, known for its bright white background and bold, elegant gray veining. Quarried in Carrara, Italy, this luxurious natural stone is a top choice for high-end interiors, exuding sophistication and timeless beauty.",
  },
  {
    _id: "1025",
    img: a10,
    productName: "Alaska White Granite",
    price: "--",
    color: "Mixed",
    badge: false,
    des: "Alaska White Granite is a stunning natural stone known for its crisp white background with gray, black, and beige veining, creating a sophisticated and timeless aesthetic. This durable and versatile granite is sourced mainly from Brazil and India, making it a popular choice for both modern and classic interiors.",
  },
  {
    _id: "1026",
    img: a11,
    productName: "Snow Black Marble",
    price: "--",
    color: "Mixed",
    badge: false,
    des: "Snow Black Marble is a stunning natural stone that combines the elegance of deep black hues with white veining, creating a dramatic yet refined look. This luxurious marble, known for its striking contrast, is perfect for modern and contemporary interiors.",
  },
  {
    _id: "1027",
    img: a12,
    productName: "River White Granite",
    price: "--",
    color: "Mixed",
    badge: false,
    des: "River White Granite is a beautiful natural stone that combines soft white and light gray tones with delicate brown, red, and black veining, resembling the flow of a gentle river. Sourced from India, this granite brings both elegance and durability to a variety of design applications.",
  },
  {
    _id: "1028",
    img: a13,
    productName: "Macobus Fantasy Quartzite",
    price: "--",
    color: "Mixed",
    badge: false,
    des: "Macobus Fantasy Quartzite is a striking and luxurious natural stone, known for its vibrant blend of white, gray, and golden tones, with dramatic, swirling patterns that create a unique, almost ethereal effect. Sourced from Brazil, this quartzite is both durable and beautiful, making it ideal for high-end design projects.",
  },
  {
    _id: "1029",
    img: a14,
    productName: "Green Quartzite",
    price: "--",
    color: "Mixed",
    badge: false,
    des: "Green Quartzite is a beautiful and unique natural stone, featuring a rich green base with intricate veining of white, gray, and occasional hints of gold. This stone brings a sense of calm and sophistication to any space, while offering the strength and durability that quartzite is known for. Sourced from Brazil, it’s an excellent choice for creating striking and nature-inspired interiors.",
  },
  {
    _id: "1030",
    img: a15,
    productName: "Fusion Gold Quartzite",
    price: "--",
    color: "Mixed",
    badge: false,
    des: "Fusion Gold Quartzite is a luxurious and dramatic natural stone that blends rich gold, beige, and brown hues with striking veins of white, gray, and dark brown, creating a mesmerizing fusion of colors and patterns. Sourced from Brazil, this quartzite is not only stunning but also offers exceptional durability, making it an ideal choice for both residential and commercial applications.",
  },
];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

// =================== PaginationItems End here =================
