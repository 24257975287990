import React from "react";

const Image = ({ imgSrc, className }) => {
  return (
    <img
      className={className}
      src={imgSrc}
      alt={imgSrc}
      style={{ width: "400px", height: "200px" }}
    />
  );
};

export default Image;
